module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logo.png","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cccf25e14aa1e03570f6e95aaf23ea56"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-TMTBXWQ"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true},"pathToEmotionCacheProps":""},
    },{
      plugin: require('../plugins/custom-mui-theme/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://pet-di.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["vi","en"],"defaultLanguage":"vi","fallbackLanguage":"vi","siteUrl":"https://pet-di.com","redirect":false,"i18nextOptions":{"keySeparator":false,"nsSeparator":":"},"pages":[{"matchPath":"/:lang?/ve-chung-toi","getLanguageFromPath":false,"excludeLanguages":["en"]},{"matchPath":"/:lang?/chinh-sach","getLanguageFromPath":false,"excludeLanguages":["en"]},{"matchPath":"/:lang?/lien-he","getLanguageFromPath":false,"excludeLanguages":["en"]},{"matchPath":"/:lang?/hoi-dap","getLanguageFromPath":false,"excludeLanguages":["en"]},{"matchPath":"/:lang?/dat-xe-dua-don-thu-cung","getLanguageFromPath":false,"excludeLanguages":["en"]},{"matchPath":"/vi","getLanguageFromPath":false,"excludeLanguages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
