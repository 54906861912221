exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-about-tsx": () => import("./../../../src/templates/About/About.tsx" /* webpackChunkName: "component---src-templates-about-about-tsx" */),
  "component---src-templates-booking-booking-tsx": () => import("./../../../src/templates/Booking/Booking.tsx" /* webpackChunkName: "component---src-templates-booking-booking-tsx" */),
  "component---src-templates-contact-contact-tsx": () => import("./../../../src/templates/Contact/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-contact-tsx" */),
  "component---src-templates-faq-faq-tsx": () => import("./../../../src/templates/FAQ/FAQ.tsx" /* webpackChunkName: "component---src-templates-faq-faq-tsx" */),
  "component---src-templates-home-home-tsx": () => import("./../../../src/templates/Home/Home.tsx" /* webpackChunkName: "component---src-templates-home-home-tsx" */),
  "component---src-templates-policy-policy-tsx": () => import("./../../../src/templates/Policy/Policy.tsx" /* webpackChunkName: "component---src-templates-policy-policy-tsx" */)
}

